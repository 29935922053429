<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
// https://github.com/nuxt-modules/icon/blob/main/src/runtime/Icon.vue
import type { PropType } from 'vue'
import type { IconifyIcon } from '@iconify/vue'
import { Icon as Iconify } from '@iconify/vue/dist/offline'
import { loadIcon } from '@iconify/vue'
import { ref, computed, watch } from 'vue'
import useSimpleState from '~/composables/useSimpleState'

const state = useSimpleState()

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: '',
  },
})

const iconName = computed(() => props.name)
const isFetching = ref(false)
const component = ref(null)
// const component = computed(() => app.component(iconName.value as string))

const sSize = computed(() => {
  // Disable size if appConfig.nuxtIcon.size === false
  // @ts-ignore
  if (!props.size) {
    return undefined
  }
  // @ts-ignore
  const size = props.size || '1em'
  if (String(Number(size)) === size) {
    return `${size}px`
  }
  return size
})

const className = computed(() => icon)
const icon = computed<IconifyIcon | undefined>(() => {
  // @ts-ignore
  return state.value?.loadedIcons[iconName.value]
})

async function loadIconComponent() {
  if (component.value) {
    return
  }

  // @ts-ignore
  if (!state.value?.loadedIcons[iconName.value]) {
    isFetching.value = true
    // @ts-ignore
    state.value.loadedIcons[iconName.value] = await loadIcon(
      iconName.value as string
    ).catch(() => undefined)
    isFetching.value = false
  }
}

watch(() => iconName.value, loadIconComponent)

!component.value && (await loadIconComponent())
</script>

<template>
  <span v-if="isFetching" :class="className" :width="sSize" :height="sSize" />
  <Iconify
    v-else-if="icon"
    :icon="icon"
    :class="className"
    :width="sSize"
    :height="sSize"
  />
  <Component
    :is="component"
    v-else-if="component"
    :class="className"
    :width="sSize"
    :height="sSize"
  />
  <span
    v-else
    :class="className"
    :style="{ fontSize: sSize, lineHeight: sSize, width: sSize, height: sSize }"
  >
    {{ name }}
  </span>
</template>

<style scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
}
</style>
